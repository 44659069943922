import React from 'react'

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 pt-10">
      <h1 className="text-xl text-center my-4 font-customSans font-semibold">
        SIMA (CANADA) INC. <br />
        TERMS AND CONDITIONS
      </h1>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          <span className="font-bold">Terms & Conditions</span>
        </h2>
        <p className="text-justify mb-4">
          By downloading or using the app, these terms will automatically apply
          to you – you should make sure therefore that you read them carefully
          before using the app. You’re not allowed to copy or modify the app,
          any part of the app, or our trademarks in any way. You’re not allowed
          to attempt to extract the source code of the app, and you also
          shouldn’t try to translate the app into other languages or make
          derivative versions. The app itself, and all the trademarks,
          copyright, database rights, and other intellectual property rights
          related to it, still belong to Sima (Canada) Inc.
        </p>
        <p className="text-justify mb-4">
          Sima (Canada) Inc. is committed to ensuring that the app is as useful
          and efficient as possible. For that reason, we reserve the right to
          make changes to the app or to charge for its services, at any time and
          for any reason. We will never charge you for the app or its services
          without making it very clear to you exactly what you’re paying for.
        </p>

        <p className="text-justify mb-4">
          The Sima app stores and processes personal data that you have provided
          to us, to provide our Service. It’s your responsibility to keep your
          phone and access to the app secure. We therefore recommend that you do
          not jailbreak or root your phone, which is the process of removing
          software restrictions and limitations imposed by the official
          operating system of your device. It could make your phone vulnerable
          to malware/viruses/malicious programs, compromise your phone’s
          security features and it could mean that the Sima app won’t work
          properly or at all.
        </p>

        <p className="text-justify mb-4">
          The app does use third-party services that declare their Terms and
          Conditions.
        </p>

        <p className="text-justify mb-4">
          Sharing Information with Third Parties <br />
        </p>
        <p className="text-justify mb-4">
          To use some of the Services, you may need to provide information such
          as credit card numbers, bank account numbers, and other sensitive
          financial information, to third parties. By using the Services, you
          agree that Sima (Canada) Inc may collect, store, and transfer such
          information on your behalf, and at your sole request. More information
          is available in our Privacy Policy. You agree that your decision to
          make available to any third party any of your sensitive or
          confidential information is your sole responsibility and at your sole
          risk.
        </p>
        <p className="text-justify mb-4">
          Refer to Terms and Conditions of the listed third-party service
          providers used by the app
        </p>
        <p className="text-justify mb-4">
          Google Play Services (Andriod) <br />
          App Store (iOS) <br />
          Mindee <br />
        </p>

        <h2 className="text-lg font-semibold">
          <span className="font-bold">Subscription terms</span>
        </h2>

        <p className="text-justify mb-4">
        A subscription is valid for 30 days, once the subscription plan is selected. Payment will be charged to your account upon confirmation of purchase. Your subscription automatically renews unless cancelled at least 24-hours before the end of the current period. Your account will be charged for renewal at the price listed within 24-hours prior to the end of the current period.

        You will get 5 (Five) free scans when you register with new account. Free subscription is available for 30 days.
        </p>

        <p className="text-justify mb-4">
          You should be aware that there are certain things that Sima (Canada)
          Inc. will not take responsibility for. Certain functions of the app
          will require the app to have an active internet connection. The
          connection can be Wi-Fi or provided by your mobile network provider,
          but Sima (Canada) Inc. cannot take responsibility for the app not
          working at full functionality if you don’t have access to Wi-Fi, and
          you don’t have any of your data allowance left.
        </p>
        <p className="text-justify mb-4">
          If you’re using the app outside of an area with Wi-Fi, you should
          remember that the terms of the agreement with your mobile network
          provider will still apply. As a result, you may be charged by your
          mobile provider for the cost of data for the duration of the
          connection while accessing the app, or other third-party charges. In
          using the app, you’re accepting responsibility for any such charges,
          including roaming data charges if you use the app outside of your home
          territory (i.e. region or country) without turning off data roaming.
          If you are not the bill payer for the device on which you’re using the
          app, please be aware that we assume that you have received permission
          from the bill payer for using the app.
        </p>

        <p className="text-justify mb-4">
          Along the same lines, Sima (Canada) Inc. cannot always take
          responsibility for the way you use the app i.e. You need to make sure
          that your device stays charged – if it runs out of battery and you
          can’t turn it on to avail the Service, Sima (Canada) Inc. cannot
          accept responsibility.
        </p>

        <p className="text-justify mb-4">
          You Are Responsible for Your Account. You are solely responsible for
          maintaining the confidentiality and security of your password and
          other Registration Information. For your protection and the protection
          of other users, we ask you not to share your Registration Information
          with anyone else. If you do share this information with anyone, we
          will consider their activities to have been authorized by you. If you
          have reason to believe that your account is no longer secure, you must
          immediately notify us at{' '}
          <a href="mailto:info@simainc.ca" className="text-sky-600 font-bold">
            info@simainc.ca
          </a>
        </p>
        <p className="text-justify mb-4">
          With respect to Sima (Canada) Inc.’s responsibility for your use of
          the app, when you’re using the app, it’s important to bear in mind
          that although we endeavor to ensure that it is updated and correct at
          all times, we do rely on third parties to provide information to us so
          that we can make it available to you. Sima (Canada) Inc. accepts no
          liability for any loss, direct or indirect, you experience as a result
          of relying wholly on this functionality of the app.
        </p>
        <p className="text-justify mb-4">
          You may need to pay for some services of Sima (Canada) Inc’s Services.
          You will acquire relevant services with the fees duly paid in full.
          Sima (Canada) Inc could make changes about payment standards and
          methods according to practical needs. Some free services may become
          paid services in the future. If the fee is not paid on time and in
          full, you will no longer be able to use the service when we start to
          charge fees. The above-mentioned change will be notified or published
          on our websites or reminded during a period of time before you use
          relevant services. The price of the services provided through the
          website may change at any time. If there is a price reduction or
          promotion, Sima (Canada) Inc. may not provide price protection or
          refund your payment.
        </p>
        <p className="text-justify mb-4">
          At some point, we may wish to update the app. The app is currently
          available on Android & iOS – the requirements for the both systems(and
          for any additional systems we decide to extend the availability of the
          app to) may change, and you’ll need to download the updates if you
          want to keep using the app. Sima (Canada) Inc. does not promise that
          it will always update the app so that it is relevant to you and/or
          works with the Android & iOS version that you have installed on your
          device. However, you promise to always accept updates to the
          application when offered to you, We may also wish to stop providing
          the app, and may terminate use of it at any time without giving notice
          of termination to you. Unless we tell you otherwise, upon any
          termination, (a) the rights and licenses granted to you in these terms
          will end; (b) you must stop using the app, and (if needed) delete it
          from your device.
        </p>
        <p className="text-justify mb-4">
          Changes to This Terms and Conditions
        </p>
        <p className="text-justify mb-4">
          We may update our Terms and Conditions from time to time. Thus, you
          are advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Terms and Conditions on
          this page.
        </p>
        <p className="text-justify mb-4">
          These terms and conditions are effective as of 2023-11-14
        </p>
        <p className="text-justify mb-4">Contact Us</p>
      </section>

      <section className="my-4">
        <p className="text-justify mb-4">
          If you have any questions or suggestions about our Terms and
          Conditions, do not hesitate to contact us at{' '}
          <a href="mailto:info@simainc.ca" className="text-sky-600 font-bold">
            info@simainc.ca
          </a>
        </p>
      </section>
    </div>
  )
}

export default TermsAndConditions
