import React from 'react';
import { FaAndroid, FaApple } from 'react-icons/fa';

const Download = () => {
  return (
    <div
      className="download-section bg-gray-100 py-60 text-center"
      id="download"
    >
      <h2 className="text-3xl font-bold mb-4">Download our app</h2>
      <p className="text-lg mb-6">Available on iOS and Android.</p>

      <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
        <a
          href="https://apps.apple.com/ca/app/sima-scan/id6476885580"
          target="_blank"
          rel="noopener noreferrer"
          className="download-button bg-black text-white py-2 px-4 rounded flex items-center gap-2 justify-center"
          style={{ width: '300px' }}
        >
          <FaApple />
          <span>Download for iOS</span>
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.simascanreceiptinvoice"
          target="_blank"
          rel="noopener noreferrer"
          className="download-button bg-green-600 text-white py-2 px-4 rounded flex items-center gap-2 justify-center"
          style={{ width: '300px' }}
        >
          <FaAndroid />
          <span>Download for Android</span>
        </a>
      </div>
    </div>
  );
};

export default Download;
