import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/nav/HeaderProfile'
import { useStateContext } from '../../../components/contexts/ContextProvider'
import UserSidebar from '../../../components/sidebar/UserSidebar'
import { FaUsers } from 'react-icons/fa6'
import { useEffect, useState } from 'react'
import BarLoader from 'react-spinners/BarLoader'
import { getAdminAllUsers } from '../../../components/functions/user'
import { getTicketsUsersManagement } from '../../../components/functions/ticket'
import { MdSupport } from 'react-icons/md'

const AdminDashboard = () => {
  const user = useSelector((state) => state.user)
  const { activeMenu } = useStateContext()
  const [users, setAllUsers] = useState([])
  const [tickets, setAllTickets] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const ticketsList = async () => {
    try {
      setLoading(true)
      const res = await getTicketsUsersManagement(user?.token, setError)
      if (res.status === 200) {
        setAllTickets(res?.data?.tickets)
      }
      setLoading(false)
    } catch (error) {}
  }

  const usersList = async () => {
    try {
      setLoading(true)
      const res = await getAdminAllUsers(user?.token, setError)
      if (res.status === 200) {
        setAllUsers(res?.data)
      }
      setLoading(false)
    } catch (error) {}
  }

  useEffect(() => {
    usersList()
    ticketsList()
  }, [])

  return (
    <>
      <div className="flex relative container mx-auto pt-1">
        {activeMenu ? (
          <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
            <UserSidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <UserSidebar />
          </div>
        )}
        <div
          className={
            activeMenu
              ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
              : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
          }
        >
          <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
          </div>
          <div>
            <div className="m-2 md:ml-8 p-2 md:p-1 bg-white rounded-1xl">
              <HeaderProfile title={`Welcome to executive admin panel`} />

              {loading && (
                <div className="flex justify-center items-center">
                  <BarLoader color="#fe0002" loading={loading} size={20} />
                </div>
              )}
              {!loading && (
                <div className="mt-10">
                  <div className="flex flex-wrap lg:flex-nowrap justify-start ">
                    <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
                      <div className="relative bg-gray-100 h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-56  p-4 pt-5 rounded-2xl ">
                        <button
                          type="button"
                          style={{
                            color: '#fe0002',
                            backgroundColor: '#ddd',
                          }}
                          className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                        >
                          <FaUsers size={30} />
                        </button>

                        <div className="text-gray-600 text-sm font-semibold my-3">
                          <p className="float-left">Users</p>
                          {users && (
                            <p className="float-right">
                              ({users && users.length})
                            </p>
                          )}
                        </div>

                        <div className="clear-both"></div>
                        <div className="flex space-x-2 text-gray-400 text-sm my-4">
                          <NavLink to={`/admin/users`}> Manage users</NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
                      <div className="relative bg-gray-100 h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-56  p-4 pt-5 rounded-2xl ">
                        <button
                          type="button"
                          style={{
                            color: '#fe0002',
                            backgroundColor: '#ddd',
                          }}
                          className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                        >
                          <MdSupport size={30} />
                        </button>

                        <div className="text-gray-600 text-sm font-semibold my-3">
                          <p className="float-left">Tickets</p>
                          {tickets && (
                            <p className="float-right">
                              ({tickets && tickets.length})
                            </p>
                          )}
                        </div>

                        <div className="clear-both"></div>
                        <div className="flex space-x-2 text-gray-400 text-sm my-4">
                          <NavLink to={`/admin/tickets`}>
                            {' '}
                            Manage tickets
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDashboard
