import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import {
  userFrontendAccountDelete,
  userFrontendAccountDeleteConfirm,
} from '../../components/functions/user'
import { toast } from 'react-toastify'

import { FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa'
import BarLoader from 'react-spinners/BarLoader'
const companyLogo = require('../../assets/sima-logo.png')

const codeInputs = Array(6).fill('')
let newInputCodeIndex = 0

const AccountDeletion = () => {
  const inputCodeRef = useRef()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  const [step, setStep] = useState(1)
  const [otp, setOtp] = useState({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' })
  const [nextInputCodeIndex, setNextInputCodeIndex] = useState(0)

  const handleChangeCodeText = (e, index) => {
    const { value } = e.target
    const cdvalue = value.replace(/\D/g, '')
    const newOtp = { ...otp }
    newOtp[index] = cdvalue
    setOtp(newOtp)

    const lastInputCodeIndex = codeInputs.length - 1

    if (!cdvalue) newInputCodeIndex = index === 0 ? 0 : index - 1
    else
      newInputCodeIndex =
        index === codeInputs.length - 1 ? lastInputCodeIndex : index + 1

    setNextInputCodeIndex(newInputCodeIndex)
  }

  useEffect(() => {
    inputCodeRef?.current?.focus()
  }, [nextInputCodeIndex])

  const isObjValid = (obj) => {
    return Object.values(obj).every((val) => val.trim())
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm()

  const email = watch('email')

  const onSubmit = async (data) => {
    const { email, message, reason } = data

    const newData = {
      email,
      message,
      reason,
    }

    setError('')
    setSuccess('')
    try {
      setLoading(true)
      const response = await userFrontendAccountDelete(newData, setError)
      if (response && response.status === 200) {
        setSuccess(response.data.message)
        setTimeout(() => {
          setSuccess('')
          setError('')
          setStep(2)
        }, 2000)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setSuccess('')
    }
  }

  const handleVerification = async () => {
    let val = ''
    Object.values(otp).forEach((v) => {
      val += v
    })
    setLoading(true)
    if (val.length < 6) {
      toast.error('Please fill all code fields')
      setLoading(false)
    } else {
      if (isObjValid(otp)) {
        let val = ''
        Object.values(otp).forEach((v) => {
          val += v
        })

        if (!val) {
          alert('Please enter the code')
          setLoading(false)
          return
        }

        const sendData = {
          email,
          verifyOtp: val,
        }

        setError('')
        setSuccess('')
        try {
          const response = await userFrontendAccountDeleteConfirm(
            sendData,
            setError
          )

          if (response && response.status === 200) {
            setSuccess(response.data.message)
            setTimeout(() => {
              setSuccess('')
              setError('')
              reset()
              setStep(1)
              setOtp({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' })
            }, 5000)
          }
          setLoading(false)
        } catch (err) {
          setLoading(false)
          setSuccess('')
        }
      }
    }
  }

  const handleSteps = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h3 className="text-2xl font-semibold text-center text-slate-900 my-3">
              Sima account deleting request
            </h3>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Registered Email
                </label>
                <input
                  id="email"
                  {...register('email', { required: true })}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errors.email && (
                  <span className="text-red-500 text-xs">
                    This field is required
                  </span>
                )}
              </div>

              <div>
                <label
                  htmlFor="reason"
                  className="block text-sm font-medium text-gray-700"
                >
                  Reason
                </label>
                <select
                  id="reason"
                  {...register('reason', { required: true })}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Select a reason</option>
                  <option value="Privacy Concerns">Privacy Concerns</option>
                  <option value="Not Using the Service Anymore">
                    Not Using the Service Anymore
                  </option>
                  <option value="Prefer a Different Service">
                    Prefer a Different Service
                  </option>
                  <option value="Data Security Concerns">
                    Data Security Concerns
                  </option>
                  <option value="Receiving Too Many Emails/Notifications">
                    Receiving Too Many Emails/Notifications
                  </option>
                  <option value="Unsatisfactory Customer Service">
                    Unsatisfactory Customer Service
                  </option>
                  <option value="Found the Service Complicated to Use">
                    Found the Service Complicated to Use
                  </option>
                  <option value="Quality of Service Issues">
                    Quality of Service Issues
                  </option>
                  <option value="Temporary or Test Account">
                    Temporary or Test Account
                  </option>
                  <option value="Other">Other </option>
                </select>
                {errors.reason && (
                  <span className="text-red-500 text-xs">
                    This field is required
                  </span>
                )}
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  {...register('message', { required: true })}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>
                {errors.message && (
                  <span className="text-red-500 text-xs">
                    This field is required
                  </span>
                )}
              </div>

              <button
                disabled={loading}
                className={`bg-customRed hover:bg-red-500 text-white font-bold py-2 px-4 rounded flex justify-center items-center w-full ${
                  loading && 'ActionButton'
                }`}
              >
                <span className="ml-2">Submit</span>
              </button>
            </form>
            <h6 className="text-center text-muted my-4 ">
              Changed mind?
              <Link
                to="/"
                as={NavLink}
                className="text-sm  hover:underline  hover:text-red-600 ml-2"
              >
                Back to home
              </Link>
            </h6>
          </>
        )
      case 2:
        return (
          <>
            <p className="text-center mt-3">
              Please enter verification code for email verification
            </p>
            <div className="flex justify-between items-center my-16">
              {codeInputs.map((inp, index) => {
                return (
                  <input
                    type="text"
                    className="text-center block w-full px-4 py-2 mt-2 text-black bg-white focus:border-gray-500 border rounded-md   focus:outline-none focus:ring focus:ring-opacity-40 mx-3 text-xl outline-none"
                    maxLength={1}
                    value={otp[index]}
                    placeholder="0"
                    name="vcode"
                    onChange={(e) => handleChangeCodeText(e, index)}
                    ref={nextInputCodeIndex === index ? inputCodeRef : null}
                    key={index}
                  />
                )
              })}
            </div>
            <br />

            <div className="flex justify-center items-center">
              <div className="px-2 pb-2 pt-2 w-3/4">
                <button
                  onClick={handleVerification}
                  disabled={loading}
                  className="bg-customRed hover:bg-red-500 text-white font-bold py-2 px-4 rounded flex justify-center items-center w-full"
                >
                  <span className="ml-2">Verify it's you</span>
                </button>
              </div>
            </div>

            <br />
          </>
        )
      default:
        return null
    }
  }

  return (
    <div className="relative flex flex-col justify-center items-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md sm:max-w-xl lg:max-w-3xl">
        <section className="hero container max-w-screen-lg mx-auto pb-2 ">
          <img
            src={companyLogo}
            alt="Sima Logo"
            className="w-32 mx-auto mb-6"
          />

          <div className="relative w-full justify-center flex items-center h-20 ">
            <div className="flex justify-center items-center absolute w-full mb-6">
              {loading && (
                <div className="flex justify-center items-center">
                  <BarLoader color="red" loading={loading} size={30} />
                </div>
              )}

              {success && (
                <h5 className="text-center text-green-500 mt-3 flex justify-center items-center">
                  <FaCheckCircle className="mr-2" />
                  {success}
                </h5>
              )}

              {error && (
                <h5 className="text-center text-red-400 my-3 flex justify-center items-center">
                  <FaExclamationTriangle className="mr-2" />
                  {error}
                </h5>
              )}
            </div>
          </div>

          {handleSteps()}
        </section>
      </div>
    </div>
  )
}

export default AccountDeletion
