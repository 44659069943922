import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaLinkedin,
  FaInstagram,
  FaEnvelope,
  FaMapMarkerAlt,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div>
      <div className="mb-8 flex justify-center items-center">
        <a
          href="https://www.linkedin.com/company/sima-canada-inc/"
          target="_blank"
          rel="noreferrer"
          className="mx-2 text-gray-700 hover:text-gray-500"
        >
          <FaLinkedin size={24} />
        </a>
        <a
          href="https://www.instagram.com/simacanadainc/?igsh=MWo5cXl4d3BvYmV2Zg%3D%3D&utm_source=qr"
          target="_blank"
          rel="noreferrer"
          className="mx-2 text-gray-700 hover:text-gray-500"
        >
          <FaInstagram size={24} />
        </a>
      </div>

      <div className="mb-16 flex flex-col justify-center items-center">
        <div className="flex items-center mx-2 my-2">
          <FaEnvelope className="mr-2" />{' '}
          <a href="mailto:support@simainc.ca">support@simainc.ca</a>
        </div>

        <div className="flex items-center mx-2 my-2">
          <FaMapMarkerAlt className="mr-2" /> Hamilton, ON, Canada
        </div>
      </div>

      <hr />

      <div className="my-10 flex justify-center items-center">
        <Link
          to="/privacy-policy"
          className="text-slate-900 mx-2 hover:text-gray-500 hover:underline"
        >
          Privacy Policy
        </Link>
        |
        <Link
          to="/terms-and-conditions"
          className="text-slate-900 mx-2 hover:text-gray-500 hover:underline"
        >
          Terms and Conditions
        </Link>
      </div>
      <div className="w-full py-5 px-4 bg-customRed text-gray-300 text-center">
        <h1 className="text-center text-white">
          © Copyright Sima (Canada) Inc. All Rights Reserved
        </h1>
      </div>
    </div>
  );
};

export default Footer;
