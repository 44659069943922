import React from 'react'
import Hero from './Hero'
import Footer from '../../components/footer/Footer'
import Contact from './Contact'
import About from './About'
import Download from './Download'
import WebNavBar from '../../components/nav/WebNavBar'
import HowItWorks from './HowItWorks'

function Home() {
  return (
    <>
      <WebNavBar />
      <Hero />
      <About />
      <HowItWorks />
      <Download />
      <Contact />

      <Footer />
    </>
  )
}

export default Home
