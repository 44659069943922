import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GoAlert } from 'react-icons/go'
import { useSelector } from 'react-redux'

const ToRedirect = () => {
  const [count, setCount] = useState(5)
  const navigate = useNavigate()

  const user = useSelector((state) => state.user)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => (currentCount > 0 ? currentCount - 1 : 0))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [navigate])

  useEffect(() => {
    if (count === 0) {
      navigate('/')
    }
  }, [count, navigate])

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col justify-center items-center">
        <GoAlert
          size={30}
          color="red"
          className="mb-2" // Margin bottom for spacing between the icon and text
        />
        <p className="text-danger h6">
          Private resource! Authentication required{' '}
        </p>
        <p className="text-muted"> Redirecting you in {count} seconds</p>
      </div>
    </div>
  )
}

export default ToRedirect
