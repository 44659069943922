import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useStateContext } from '../../../components/contexts/ContextProvider'
import {
  getTicketByIdUser,
  ticketReplyByAdmin,
} from '../../../components/functions/ticket'
import UserSidebar from '../../../components/sidebar/UserSidebar'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/nav/HeaderProfile'
import BarLoader from 'react-spinners/BarLoader'
import moment from 'moment'
import { useForm } from 'react-hook-form'

const AdminReplyTicket = () => {
  const { register, handleSubmit, reset } = useForm()
  const messagesEndRef = useRef(null)
  const user = useSelector((state) => state.user)
  const { activeMenu } = useStateContext()
  const [data, setAllData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { ticketId } = useParams()
  const [ticket, setTicket] = useState(null)

  const fetchTicket = async () => {
    try {
      setLoading(true)
      const res = await getTicketByIdUser(ticketId, user?.token, setError)
      if (res && res.status === 200) {
        setTicket(res.data.ticket)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTicket()
  }, [ticketId])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (ticket) {
      scrollToBottom()
    }
  }, [ticket])

  const onSubmit = async (data) => {
    const { status, reply } = data

    const newData = {
      ticketId: ticketId,
      messageText: reply,
      status,
      role: user.role,
    }

    try {
      setLoadingData(true)
      const res = await ticketReplyByAdmin(newData, user?.token, setError)
      if (res && res.status === 200) {
        setSuccess(res.data.message)
        fetchTicket()
        reset()
      }
      setLoadingData(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      setLoadingData(false)
    }
  }

  return (
    <>
      <div className="flex relative container mx-auto pt-1">
        {activeMenu ? (
          <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
            <UserSidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <UserSidebar />
          </div>
        )}
        <div
          className={
            activeMenu
              ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
              : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
          }
        >
          <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
          </div>
          <div>
            <div className="m-2 md:ml-8 p-2 md:p-1 bg-white rounded-1xl">
              {ticket && (
                <div className="mb-2">
                  <p className="text-md mt-4">
                    <strong>ID:</strong> #{ticket.ticketNumber}
                  </p>
                 <div className="flex justify-between items-center my-3">
                 <p className="text-xl">
                    <strong>Subject:</strong> {ticket?.subject}
                  </p>
                 <p className="text-xl">
                    <strong>Email:</strong> 
                    <span className='text-sky-600 mx-1'>{ticket?.user?.email}</span>
                  </p>
                 </div>

                  <div className="flex justify-between items-center my-3">
                    <p className="text-md">
                      <strong>Reason:</strong> {ticket.reason}
                    </p>
                    <p className="text-md capitalize">
                      <strong>Status:</strong>{' '}
                      <span
                        className={` px-3 text-white ml-1 rounded-lg flex-row justify-center items-center ${
                          ticket?.status === 'pending'
                            ? 'bg-red-500'
                            : ticket?.status === 'waiting'
                            ? 'bg-orange-500'
                            : ticket?.status === 'solved'
                            ? 'bg-gray-500'
                            : 'bg-blue-500'
                        }`}
                      >
                        {ticket.status}
                      </span>
                    </p>
                  </div>

                  <div className="flex justify-between items-center my-1">
                    <p className="text-md">
                      <strong>Created:</strong>{' '}
                      {moment(ticket?.createdAt).fromNow()}
                    </p>
                    <p className="text-md">
                      <strong>Last activity:</strong>{' '}
                      {moment(ticket?.updatedAt).fromNow()}
                    </p>
                  </div>

                  <br />
                  <hr />
                </div>
              )}

              {loading && (
                <div className="flex justify-center items-center">
                  <BarLoader color="#fe0002" loading={loading} size={20} />
                </div>
              )}
              {!loading && (
                <div className="overflow-x-auto">
                  {!ticket && (
                    <div className="flex justify-center items-center">
                      <p className="text-slate-900">No data found</p>
                    </div>
                  )}
                </div>
              )}

              {!loading && ticket && (
                <div className="flex flex-col h-[35rem] overflow-y-auto p-4">
                  {ticket?.messages.map((message, index) => (
                    <div key={index} className="mb-4">
                      <div className="flex items-center">
                        <img
                          src={message.user.profile_image}
                          className="w-10 h-10 rounded-full mr-2"
                          alt="Profile"
                        />
                        <div>
                          <div className="text-slate-600 font-bold">
                            {message.user.name}
                          </div>
                          <div className="text-slate-500 text-xs">
                            {moment(message?.createdAt).fromNow()}
                          </div>
                        </div>
                      </div>
                      <p className="my-3 pl-12">{message.message}</p>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
              )}

              {!loading && ticket && (
                <div>
                  {loadingData && (
                    <div className="flex justify-center items-center">
                      <BarLoader
                        color="#fe0002"
                        loading={loadingData}
                        size={20}
                      />
                    </div>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <div className="mb-4">
                      <label className="font-bold mr-2">
                        Ticket marked as:
                      </label>
                      <label className="mr-2">
                        <input
                          {...register('status')}
                          type="radio"
                          value="pending"
                          defaultChecked={ticket?.status === 'pending'}
                          className="mr-1"
                        />
                        Pending
                      </label>
                      <label className="mr-2">
                        <input
                          {...register('status')}
                          type="radio"
                          value="waiting"
                          defaultChecked={ticket?.status === 'waiting'}
                          className="mr-1"
                        />
                        Waiting
                      </label>
                      <label>
                        <input
                          {...register('status')}
                          type="radio"
                          value="solved"
                          defaultChecked={ticket?.status === 'solved'}
                          className="mr-1"
                        />
                        Solved
                      </label>
                    </div>

                    <textarea
                      {...register('reply', { required: true })}
                      className="w-full p-2 border rounded"
                      placeholder="Your reply here..."
                    />

                    <button
                      disabled={loadingData}
                      type="submit"
                      className={`bg-customRed mt-2 hover:bg-red-500 text-white font-bold py-2 px-4 rounded flex justify-center items-center w-full ${
                        loadingData && 'ActionButton'
                      }`}
                    >
                      Send
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminReplyTicket
