import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GoAlert } from 'react-icons/go'
import { useSelector } from 'react-redux'

const NoRedirect = () => {
  const [count, setCount] = useState(3)
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount)
    }, 1000)
    if (count === 0) {
      user?.role === 'admin'
        ? navigate('/admin/dashboard')
        : navigate('/dashboard')
    }

    // cleanup
    return () => clearInterval(interval)
  }, [count, navigate])

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col justify-center items-center">
        <GoAlert size={30} color="green" className="mb-2" />
        <p className="text-danger h6">You are already logged In </p>
        <p className="text-muted"> Redirecting you in {count} seconds</p>
      </div>
    </div>
  )
}

export default NoRedirect
