import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { googleLogout } from '@react-oauth/google';
const logo = require('../../assets/logo.png');

const WebNavBar = () => {
  const [currentSection, setCurrentSection] = useState('');
  let Links = [
    { name: 'HOME', id: 'home' },
    { name: 'ABOUT', id: 'about' },
    { name: 'HOW IT WORKS', id: 'howItWorks' },
    { name: 'DOWNLOAD', id: 'download' },
    { name: 'CONTACT', id: 'contact' },
  ];
  let [open, setOpen] = useState(false);

  const [openDrop, setOpenDrop] = useState(false);
  const divRef = useRef();

  const [scroll, setScroll] = useState(false);

  const handleScroll = useCallback(() => {
    let newSection = 'home';
    let smallestDistance = Infinity;

    Links.forEach((link) => {
      const element = document.getElementById(link.id);
      const bounding = element.getBoundingClientRect();
      const distance = Math.abs(bounding.top);

      if (distance < smallestDistance) {
        smallestDistance = distance;
        newSection = link.id;
      }
    });

    setCurrentSection(newSection);

    setScroll(window.scrollY > 0);
  }, [Links]);

  useEffect(() => {
    const debouncedHandleScroll = handleScroll;
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenDrop(false);
      }
    };

    window.addEventListener('mouseup', handleClickOutside);

    return () => {
      window.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  const smoothScroll = (id) => (e) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setOpen(false);
    }
  };

  const getLinkClass = (id) => {
    const baseClass =
      'block py-2 pl-2 pr-4 text-base rounded md:p-0 duration-500';
    const activeClass = 'text-red-600 underline';
    const normalClass = 'text-red-500 md:hover:text-red-600';
    return `${baseClass} ${currentSection === id ? activeClass : normalClass}`;
  };

  return (
    <div
      className={
        scroll
          ? 'flex items-center justify-center fixed top-0 left-0 w-full h-16 z-50 transition-all duration-500 bg-orange-50'
          : 'flex justify-between items-center h-20 mx-auto px-4 text-red-500 relative z-50 transition-all duration-300 bg-orange-50 w-full'
      }
    >
      <nav className="container mx-auto">
        <div className="flex flex-wrap items-center justify-between mx-auto p-2">
          <Link to="/" as={NavLink} className="flex items-center">
            <img
              src={logo}
              alt="Sima"
              className="object-contain w-28 relative -top-1"
            />
          </Link>

          <div ref={divRef} className="relative flex items-center md:order-2">
            <button
              data-collapse-toggle="navbar-user"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden  focus:outline-none "
              aria-controls="navbar-user"
              aria-expanded="false"
              onClick={() => setOpen(!open)}
            >
              {!open ? (
                <AiOutlineMenu size={25} />
              ) : (
                <AiOutlineClose size={25} />
              )}
            </button>

            <ul
              className={`md:flex md:items-center md:pb-0 pb-12 md:static md:z-auto left-0  md:w-auto navbar-web   ${
                open
                  ? 'fixed left-0 top-0 w-[60%] h-full bg-orange-50 ease-in-out duration-500'
                  : 'ease-in-out duration-500 fixed left-[-100%]'
              }`}
            >
              {Links.map((link, i) => (
                <li
                  key={i}
                  className="md:ml-8 sm:ml-0 text-base md:my-0 my-2 flex-shrink-0"
                >
                  <a
                    href={`#${link.id}`}
                    onClick={smoothScroll(link.id)}
                    className={getLinkClass(link.id)}
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default WebNavBar;
