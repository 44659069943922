import axios from 'axios'
import catchErrors from '../../utils/catchErrors'

export const getTicketsUsersManagement = async (token, setError) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}get-all-tickets`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setError(null)
    return res
  } catch (error) {
    const errorMsg = catchErrors(error)
    setError(errorMsg)
  }
}

export const getTicketByIdUser = async (ticketId, token, setError) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}tickets/${ticketId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setError(null)
    return res
  } catch (error) {
    const errorMsg = catchErrors(error)
    setError(errorMsg)
  }
}

export const ticketReplyByAdmin = async (newdata, authtoken, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}reply-to-ticket-admin`,
      newdata,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )

    setError(null)
    return res
  } catch (error) {
    const errorMsg = catchErrors(error)
    setError(errorMsg)
  }
}
