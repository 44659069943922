import React from 'react'
import secBg from '../../assets/sec-bg.jpg'
import { TbBrandGoogleAnalytics } from 'react-icons/tb'
import { GiExpense, GiExtractionOrb } from 'react-icons/gi'
import { FaDatabase } from 'react-icons/fa'
import { FaApple, FaAndroid } from 'react-icons/fa'

const About = () => {
  const backgroundUrl = `url(${secBg})`
  return (
    <>
      <div
        className="w-full py-20 px-4 bg-fixed bg-cover bg-center overflow-hidden"
        id="about"
        style={{ backgroundImage: backgroundUrl }}
      >
        <div className="max-w-[1240px] mx-auto pt-20">
          <div className="mb-10">
            <h3 className="text-3xl font-bold">
              Scan, Save and Extract Receipts Data with Sima App
            </h3>
            <p className="my-5 text-md text-justify">
              Sima App is designed to be a simplified user-friendly app where
              users scan and extract data from their receipts as a form of
              report. It allows users to take pictures or select from the photo
              library expense receipt and convert into digital format with
              details of purchase items, vendor, date, amount, sales tax, and
              total amount, extracted. The app also gives the user the ability
              to edit or rename any item or information not captured.
              <br /> <br />
              Sima App users can categorize and track expenses using Sima
              Analytics.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 text-center">
            <div className="flex flex-col items-center justify-center my-6">
              <div className="h-36 flex flex-col justify-center items-center">
                <GiExtractionOrb size={40} color="#fe0002" />
                <h2 className="text-2xl font-bold text_color_new my-2">
                  App Overview
                </h2>
              </div>
              <p className="text-gray-600 h-20">
                Sima app is a user-friendly tool designed for extracting and
                managing data from receipts.
              </p>
            </div>

            <div className="flex flex-col items-center justify-center my-6">
              <div className="h-36 flex flex-col justify-center items-center">
                <GiExpense size={40} color="#fe0002" />
                <h2 className="text-2xl font-bold text_color_new my-2">
                  Expense
                </h2>
              </div>

              <p className="text-gray-600 h-20">
                User interaction aspect, explaining how users can categorize
                expenses and the process involved in entering an expense
                receipt.
              </p>
            </div>

            <div className="flex flex-col items-center justify-center my-6">
              <div className="h-36 flex flex-col justify-center items-center">
                <FaDatabase size={40} color="#fe0002" />
                <h2 className="text-2xl font-bold text_color_new my-2">
                  Data Extraction
                </h2>
              </div>

              <p className="text-gray-600 h-20">
                Users can extract their financial data in CSV format and email
                saved receipts.
              </p>
            </div>

            <div className="flex flex-col items-center justify-center my-6 ">
              <div className="h-36 flex flex-col justify-center items-center">
                <TbBrandGoogleAnalytics size={40} color="#fe0002" />
                <h2 className="text-2xl font-bold text_color_new my-2">
                  Analytical Insights
                </h2>
              </div>

              <p className="text-gray-600 h-20">
                This feature is likely aimed at providing users with insights
                into their spending patterns, enabling them to make informed
                financial decisions based on their expense history.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
