import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { RiLockPasswordLine } from 'react-icons/ri'
import BarLoader from 'react-spinners/BarLoader'
import {
  loginUser,
  emailRequestForget,
  forgetPasswordCodeCheck,
  forgetPasswordUpdateRequest,
} from '../../components/functions/user'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import { BiUserCheck } from 'react-icons/bi'
import { toast } from 'react-toastify'
import { GoAlert } from 'react-icons/go'
import { FaRegCircleCheck } from 'react-icons/fa6'
// import GoogleAuth from './GoogleAuth'
const companyLogo = require('../../assets/sima-logo.png')

const codeInputs = Array(6).fill('')
let newInputCodeIndex = 0

const Login = () => {
  const inputCodeRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [step, setStep] = useState(1)
  const [otp, setOtp] = useState({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' })
  const [nextInputCodeIndex, setNextInputCodeIndex] = useState(0)

  const handleChangeCodeText = (e, index) => {
    const { value } = e.target
    const cdvalue = value.replace(/\D/g, '')
    const newOtp = { ...otp }
    newOtp[index] = cdvalue
    setOtp(newOtp)

    const lastInputCodeIndex = codeInputs.length - 1

    if (!cdvalue) newInputCodeIndex = index === 0 ? 0 : index - 1
    else
      newInputCodeIndex =
        index === codeInputs.length - 1 ? lastInputCodeIndex : index + 1

    setNextInputCodeIndex(newInputCodeIndex)
  }

  useEffect(() => {
    inputCodeRef?.current?.focus()
  }, [nextInputCodeIndex])

  const isObjValid = (obj) => {
    return Object.values(obj).every((val) => val.trim())
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const {
    register: registerForget,
    handleSubmit: handleSubmitEmail,
    watch: watchEmail,
    formState: { errors: errorsEmail },
    reset: resetEmail,
  } = useForm()

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
    watch: watchPassword,
    reset: resetPassword,
  } = useForm()

  const emailOrUsrForget = watchEmail('identifierUsrOrEmail')

  const onSubmit = async (formData) => {
    const { email, password } = formData

    const sendData = {
      email,
      password,
    }

    setError('')
    setSuccess('')
    try {
      setLoading(true)
      const response = await loginUser(sendData, setError)
      setSuccess(response.data.message)
      setTimeout(() => {
        setSuccess('')
        setError('')
        reset()
        dispatch({ type: 'LOGIN', payload: response?.data?.user })
        Cookies.set('user', JSON.stringify(response?.data?.user), {
          expires: 30,
        })

        response?.data?.user?.role === 'admin'
          ? navigate('/admin/dashboard')
          : navigate('/dashboard')
      }, 2000)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setSuccess('')
    }
  }

  const emailSubmit = async (emailData) => {
    const { identifierUsrOrEmail } = emailData

    const sendData = {
      identifierUsrOrEmail,
    }

    setError('')
    setSuccess('')
    try {
      setLoading(true)
      const response = await emailRequestForget(sendData, setError)
      setSuccess(response.data.message)
      setTimeout(() => {
        setSuccess('')
        setError('')
        setStep(3)
      }, 2000)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setSuccess('')
    }
  }

  const handleVerification = async (e) => {
    e.preventDefault()
    let val = ''
    Object.values(otp).forEach((v) => {
      val += v
    })
    setLoading(true)
    if (val.length < 6) {
      toast.error('Please fill all code fields')
      setLoading(false)
    } else {
      if (isObjValid(otp)) {
        let val = ''
        Object.values(otp).forEach((v) => {
          val += v
        })

        if (!val) {
          alert('Please enter the code')
          setLoading(false)
          return
        }

        let sendData = {
          emailOrUsrForget,
          vcode: val,
        }

        try {
          setLoading(true)
          const response = await forgetPasswordCodeCheck(sendData, setError)
          setSuccess(response.data.message)
          setTimeout(() => {
            setSuccess('')
            setError('')
            setStep(4)
          }, 2000)
          setLoading(false)
        } catch (err) {
          setLoading(false)
          setSuccess('')
        }
      }
    }
  }

  const handleUpdatePassword = async (passwordData) => {
    const { newPassword, confirmPassword } = passwordData

    if (newPassword !== confirmPassword) {
      toast.error('Password does not match')
      return
    }

    let val = ''
    Object.values(otp).forEach((v) => {
      val += v
    })
    if (val.length < 6) {
      toast.error('Please fill all code fields')
      setLoading(false)
    } else {
      if (isObjValid(otp)) {
        let val = ''
        Object.values(otp).forEach((v) => {
          val += v
        })

        if (!val) {
          alert('Please enter the code')
          setLoading(false)
          return
        }

        let sendData = {
          emailOrUsrForget,
          password: newPassword,
          vcode: val,
        }

        setError('')
        setSuccess('')
        try {
          setLoading(true)
          const response = await forgetPasswordUpdateRequest(sendData, setError)
          setSuccess(response.data.message)
          setTimeout(() => {
            setSuccess('')
            setError('')
            reset()
            resetEmail()
            resetPassword()
            setOtp({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' })
            setStep(1)
          }, 2000)
          setLoading(false)
        } catch (err) {
          setLoading(false)
          setSuccess('')
        }
      }
    }
  }

  const handleSteps = () => {
    switch (step) {
      case 1:
        return (
          <>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-16">
              <div className="my-6">
                <div className="w-full inline-flex ">
                  <input
                    id="email"
                    type="text"
                    {...register('email', {
                      required: 'Email is required',
                    })}
                    className="shadow appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Email"
                  />
                </div>

                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <div className="mt-6 mb-2">
                <div className="w-full inline-flex ">
                  <input
                    id="password"
                    type="password"
                    {...register('password', {
                      required: 'Password is required',
                    })}
                    className="shadow appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Password"
                  />
                </div>

                {errors.password && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.password.message}
                  </p>
                )}
              </div>

              <div className="flex justify-end items-center my-4">
                <p onClick={() => setStep(2)} className="mb-2 cursor-pointer">
                  Forgot password?
                </p>
              </div>

              <button
                disabled={loading}
                className={`bg-customRed hover:bg-red-500 text-white font-bold py-3 px-4 rounded flex justify-center items-center w-full ${
                  loading && 'ActionButton'
                }`}
              >
                <span className="ml-2">Login</span>
              </button>
            </form>

            {/* <p className="w-full my-6 text-white text-center">OR</p> */}

            {/* <GoogleAuth
              setLoading={setLoading}
              loading={loading}
              success={success}
              setSuccess={setSuccess}
              error={error}
              setError={setError}
            /> */}
          </>
        )

      case 2:
        return (
          <>
            <form onSubmit={handleSubmitEmail(emailSubmit)} className="mt-16">
              <div className="my-6">
                <div className="w-full inline-flex bg-gray-600">
                  <div className="w-1/12 flex justify-center items-center ">
                    <BiUserCheck size={25} />
                  </div>
                  <input
                    id="identifierUsrOrEmail"
                    type="text"
                    {...registerForget('identifierUsrOrEmail', {
                      required: 'Email or username is required',
                    })}
                    className="block w-full p-2 text-lg rounded-sm bg-black focus:border-gray-500 outline-none"
                    placeholder="Email or username "
                  />
                </div>

                {errorsEmail.identifierUsrOrEmail && (
                  <p className="text-red-500 text-xs mt-1">
                    {errorsEmail.identifierUsrOrEmail.message}
                  </p>
                )}
              </div>

              <div className="px-2 pb-2 pt-2">
                <button className="uppercase block w-full p-2 text-lg rounded-full bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
                  Reset password
                </button>
              </div>
            </form>

            <div className="flex justify-center items-center">
              <button
                onClick={() => setStep(1)}
                className="text-center text-muted my-4 "
              >
                Login
              </button>
            </div>
          </>
        )
      case 3:
        return (
          <>
            <p className="text-center mt-16">
              Please enter verification code for password changing.
            </p>
            <div className="flex justify-between items-center my-16">
              {codeInputs.map((inp, index) => {
                return (
                  <input
                    type="text"
                    className="text-center block w-full px-4 py-2 mt-2 text-white bg-black focus:border-gray-500 border rounded-md   focus:outline-none focus:ring focus:ring-opacity-40 mx-3 text-xl outline-none"
                    maxLength={1}
                    value={otp[index]}
                    placeholder="0"
                    name="vcode"
                    onChange={(e) => handleChangeCodeText(e, index)}
                    ref={nextInputCodeIndex === index ? inputCodeRef : null}
                    key={index}
                  />
                )
              })}
            </div>
            <br />

            <div className="flex justify-center items-center">
              <div className="px-2 pb-2 pt-2 w-3/4">
                <button
                  onClick={handleVerification}
                  disabled={loading}
                  className="uppercase block w-full p-2 text-lg rounded-full bg-indigo-500 hover:bg-indigo-600 focus:outline-none"
                >
                  Verify Code
                </button>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <button
                onClick={() => setStep(1)}
                className="text-center text-muted my-4 "
              >
                Login
              </button>
            </div>

            <br />
          </>
        )
      case 4:
        return (
          <>
            <p className="text-center mt-16">Create new password</p>

            <form
              onSubmit={handleSubmitPassword(handleUpdatePassword)}
              className="mt-16"
            >
              <div className="mt-6 mb-2">
                <div className="w-full inline-flex bg-gray-600">
                  <div className="w-1/12 flex justify-center items-center ">
                    <RiLockPasswordLine size={25} />
                  </div>
                  <input
                    id="newPassword"
                    type="password"
                    {...registerPassword('newPassword', {
                      required: 'Password is required',
                    })}
                    className="block w-full p-2 text-lg rounded-sm bg-black focus:border-gray-500 outline-none"
                    placeholder="Password"
                  />
                </div>

                {errorsPassword.newPassword && (
                  <p className="text-red-500 text-xs mt-1">
                    {errorsPassword.newPassword.message}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <div className="w-full inline-flex bg-gray-600">
                  <div className="w-1/12 flex justify-center items-center ">
                    <RiLockPasswordLine size={25} />
                  </div>
                  <input
                    id="confirmPassword"
                    type="password"
                    {...registerPassword('confirmPassword', {
                      required: 'Confirm password is required',
                      validate: (value) =>
                        value === watchPassword('newPassword') ||
                        'Passwords do not match',
                    })}
                    className="block w-full p-2 text-lg rounded-sm bg-black focus:border-gray-500 outline-none"
                    placeholder="Confirm password"
                  />
                </div>

                {errorsPassword.confirmPassword && (
                  <p className="text-red-500 text-xs mt-1">
                    {errorsPassword.confirmPassword.message}
                  </p>
                )}
              </div>

              <div className="px-2 pb-2 pt-2">
                <button className="uppercase block w-full p-2 text-lg rounded-full bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
                  Create new password
                </button>
              </div>
            </form>

            <div className="flex justify-center items-center">
              <button
                onClick={() => setStep(1)}
                className="text-center text-muted my-4 "
              >
                Login
              </button>
            </div>
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <div className="grid h-screen place-items-center">
        <div className="w-full p-6 m-auto  rounded-md shadow-sm shadow-red-300 sm:max-w-xl lg:max-w-xl">
          <section className="hero container max-w-screen-lg mx-auto">
            <img
              className="mx-auto"
              alt="Sima logo"
              src={companyLogo}
              style={{
                width: '120px',
                objectFit: 'contain',
              }}
            />
          </section>
          <h1 className="text-xl font-semibold mb-6 text-center mt-6">
            {step === 1
              ? 'Management portal'
              : step === 2
              ? 'Enter email to reset your password'
              : step === 3
              ? 'Verification Code'
              : 'Create new password'}
          </h1>

          <div className="relative w-full">
            <div className="flex justify-center items-center h-1 absolute w-full mb-6">
              {loading && (
                <div className="flex justify-center items-center h-screen">
                  <BarLoader color="red" loading={loading} size={30} />
                </div>
              )}

              {success && (
                <h5 className="text-center text-green-500 auth_error_success text-success flex justify-content-center align-items-center">
                  <FaRegCircleCheck
                    size={20}
                    style={{
                      marginTop: 2,
                    }}
                  />
                  <span className="ml-1">{success}</span>
                </h5>
              )}
              {error && (
                <h5 className="text-center text-red-400 auth_error_success text-danger flex justify-content-center align-items-center">
                  <GoAlert
                    size={20}
                    color="red"
                    style={{
                      marginTop: 1,
                    }}
                  />
                  <span className="ml-1">{error}</span>
                </h5>
              )}
            </div>
          </div>

          {handleSteps()}
        </div>
      </div>
    </>
  )
}

export default Login
