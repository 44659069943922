import React from 'react'

const HeaderProfile = ({ title }) => (
  <div className="mb-10">
    <p className="text-2xl font-bold tracking-tight text-slate-600 ml-2">
      {title}
    </p>
  </div>
)

export default HeaderProfile
