import { Route, Routes } from 'react-router-dom'
import NotLoggedInRoute from './components/routes/NotLoggedInRoute'
import ModeratorRoute from './components/routes/ModeratorRoute'
import UserRoute from './components/routes/UserRoute'
import AdminRoute from './components/routes/AdminRoute'
import Home from './Pages/Home/Home'
import Report from './Pages/Report/Report'
import PrivacyPolicy from './Pages/Privacy/Privacy'
import TermsAndConditions from './Pages/Terms/TermsAndConditions'
import AccountDeletion from './Pages/AccountRequest/AccountDeletion'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminDashboard from './Pages/Admin/Dashboard/AdminDashboard'
import NotFound from './Pages/NotFound/NotFound'
import Login from './Pages/Auth/Login'
import Dashboard from './Pages/User/Dashboard/Dashboard'
import AdminTickets from './Pages/Admin/Tickets/AdminTickets'
import AdminReplyTicket from './Pages/Admin/Tickets/AdminReplyTicket'
import AdminUsers from './Pages/Admin/User/AdminUser'
import Settings from './Pages/Settings/Settings'
import AdminSubscriptoin from './Pages/Admin/Subscription/AdminSubscriptoin'

const App = () => {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/account-deleting-request" element={<AccountDeletion />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/generated-report/:code/:id" element={<Report />} />

        <Route element={<NotLoggedInRoute />}>
          <Route exact path="/login" element={<Login />} />
        </Route>

        <Route element={<UserRoute />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/dashboard/settings" element={<Settings />} />
        </Route>

        <Route element={<ModeratorRoute />}></Route>

        <Route element={<AdminRoute />}>
          <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
          <Route exact path="/admin/tickets" element={<AdminTickets />} />
          <Route exact path="/admin/users" element={<AdminUsers />} />
          <Route exact path="/admin/users-subscription" element={<AdminSubscriptoin />} />
          <Route
            exact
            path="/admin/tickets/request/:ticketId"
            element={<AdminReplyTicket />}
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App
