import axios from 'axios'

export const getUser = async (code, id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}user-found/${code}/${id}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}
