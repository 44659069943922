import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 pt-10">
      <h1 className="text-xl text-center my-4 font-customSans font-semibold">
        SIMA (CANADA) INC. <br />
        PRIVACY POLICY
      </h1>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          1.<span className="underline ml-1">INTRODUCTION</span>
        </h2>
        <p className="text-justify mb-2">
          This Privacy Policy applies to the processing of personal information
          by Sima (Canada) Inc., a company duly registered under the laws of
          Canada with its address at 101 Shoreview Place, Ontario, Canada. This
          Privacy Policy is in accordance with the principles outlined in the
          Personal Information Protection and Electronic Documents Act (PIPEDA)
          and other applicable privacy laws and regulations.
        </p>
        <p className="text-justify mb-2">
          By continuing to use the services or access our platform you agree to
          this privacy policy. If you do not agree to this privacy policy, you
          may not be availed of the services. We advise you to carefully read
          this Privacy Policy to better understand how we utilize your personal
          information.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          2.
          <span className="underline ml-1">
            COLLECTION OF PERSONAL INFORMATION
          </span>
        </h2>
        <p className="text-justify mb-2">
          We collect this personal information when Users interact with our App
          (Sima), website, products, or services. This may include but is not
          limited to name/nickname, email address, phone number, and credit card
          information. We collect this information for the purpose of providing
          and improving our services, processing transactions and communicating
          with Users.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          3.
          <span className="underline ml-1">USE OF PERSONAL INFORMATION</span>
        </h2>
        <p className="text-justify mb-2">
          We use the collected information for the following purposes: <br />
          - To provide and maintain services to Users. <br />
          - To process transactions and generate invoices/receipts. <br />
          - To communicate with Users regarding their orders, inquiries, or
          requests. <br />
          - To send periodic emails and updates about our services, promotions,
          or other relevant information. <br />
          Users may choose to opt out of receiving these communications.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          4.
          <span className="underline ml-1">
            DISCLOSURE OF PERSONAL INFORMATION
          </span>
        </h2>
        <p className="text-justify mb-2">
          We do not sell, trade, or rent User’s personal information to third
          parties. However, we may share personal information with trusted
          Service providers like Stripe (payment platform), Mindee, Google Play
          Store (Android) and App Store (iOS) who assist us in operating our
          App, website or conducting our business, as long as these parties
          agree to keep the information confidential and use it only for the
          purposes specified by Sima (Canada) Inc.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          5.
          <span className="underline ml-1">INFORMATION RETENTION</span>
        </h2>
        <p className="text-justify mb-2">
          We retain personal information only for as long as necessary to
          fulfill the purposes for which it was collected, including legal,
          accounting, or reporting requirements. Personal information used for
          transactions, invoices, or receipts is retained for a minimum of seven
          years, as required by Canadian tax and accounting laws.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          6.
          <span className="underline ml-1">SECURITY MEASURES</span>
        </h2>
        <p className="text-justify mb-2">
          We are committed to ensuring the security of User’s personal
          information and have implemented appropriate physical, electronic, and
          managerial procedures to safeguard and secure the information we
          collect on our Platform from loss, unauthorized use, and alteration.
        </p>
        <p className="text-justify mb-2">
          You are solely responsible for any Personal Information you choose to
          disclose to third parties, including your password and User Account
          information. You can lose significant control over your personal
          information and face legally enforceable actions if you lose control
          of your user account.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          7.
          <span className="underline ml-1">CONSENT</span>
        </h2>
        <p className="text-justify mb-2">
          By providing us with their personal information, Users consent to the
          collection, use, and disclosure of that information in accordance with
          this Privacy Policy.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          8.
          <span className="underline ml-1">USERS RIGHTS</span>
        </h2>

        <ul className="list-disc pl-10 mb-2">
          <li className="underline">The right to be informed</li>
        </ul>

        <p className="text-justify mb-2">
          To emphasize the need for transparency over the usage of personal
          information, we ensure fair processing of information typically
          through this Privacy Policy.
        </p>

        <ul className="list-disc pl-10 mb-2">
          <li className="underline">The rights to access</li>
        </ul>

        <p className="text-justify">
          Users have the right to access their personal information held by Sima
          (Canada) Inc. and obtain information about how we process it.
        </p>

        <ul className="list-disc pl-10 mb-2">
          <li className="underline">The right to restrict processing</li>
        </ul>

        <p className="text-justify">
          Users have a right to restrict or withdraw their consent to our
          processing of your information, which you can do at any time.
        </p>

        <ul className="list-disc pl-10 mb-2">
          <li className="underline">The right to rectification</li>
        </ul>

        <p className="text-justify">
          Users are entitled to have personal information rectified if it is
          inaccurate or incomplete.
        </p>

        <ul className="list-disc pl-10 mb-2">
          <li className="underline">The right to erasure</li>
        </ul>

        <p className="text-justify">
          Users have the right to request the deletion or removal of personal
          information where there is no compelling legal or regulatory
          requirement for its continued processing.
        </p>

        <ul className="list-disc pl-10 mb-2">
          <li className="underline">The right to information portability</li>
        </ul>

        <p className="text-justify">
          Users have the right to request for their personal information to be
          moved, copied or transmitted from one IT infrastructure to another and
          we will make sure that it is done in a secure manner, without
          compromising usability.
        </p>

        <ul className="list-disc pl-10 mb-2">
          <li className="underline">The right to object</li>
        </ul>

        <p className="text-justify">
          Users have the right to object to our processing of their Personal
          information in some circumstances. The right not to be subject to
          automated decision-making Sima (Canada)Inc. does not make decisions
          based solely on automated means including profiling except informed
          consent of the User is obtained.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          9.
          <span className="underline ml-1">CHANGES TO THIS PRIVACY POLICY</span>
        </h2>
        <p className="text-justify mb-2">
          Sima (Canada) Inc reserves the right to update, modify, or change this
          Privacy Policy at any time without prior notice. Users are encouraged
          to review this Privacy Policy periodically for any updates or changes.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-lg font-semibold">
          10. <span className="underline ml-1">CONTACT US </span>
        </h2>
        <p className="text-justify mb-2">
          If you have any questions, concerns, or complaints about our Privacy
          Policy or our handling of personal information, please contact us at:
        </p>
        <p className="text-justify mb-2 font-bold">
          The Data Protection Officer
        </p>
        <p className="text-justify mb-2">
          Sima (Canada)Inc <br />
          101 Shoreview Place, <br />
          Ontario, Canada <br />
        </p>
        <p className="text-justify mb-2">
          <a href="mailto:info@simainc.ca" className="text-sky-600 font-bold">
            info@simainc.ca
          </a>
          <br /> <br />
          If you have a complaint about the way we use your information or how
          we respond to your requests and questions related to privacy, you can
          also send a complaint to the Office of the Privacy Commissioner of
          Canada via this link <br /> <br />
          <a
            href="https://www.priv.gc.ca/en/report-a-concern/"
            className="text-sky-600 font-bold"
          >
            https://www.priv.gc.ca/en/report-a-concern/
          </a>
          <br /> <br />
        </p>
      </section>

      <footer className="text-center my-2">
        <p>This Privacy Policy was last updated on 17th November, 2023</p>
      </footer>
    </div>
  )
}

export default PrivacyPolicy
