import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { FaRegCircleCheck, FaUsers } from 'react-icons/fa6';
import { useEffect, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';

import { MdSupport } from 'react-icons/md';
import { useStateContext } from '../../components/contexts/ContextProvider';
import UserSidebar from '../../components/sidebar/UserSidebar';
import Navbar from '../../components/nav/Navbar';
import HeaderProfile from '../../components/nav/HeaderProfile';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateUserPassword } from '../../components/functions/user';
import { GoAlert } from 'react-icons/go';

const Settings = () => {
  const user = useSelector((state) => state.user);
  const { activeMenu } = useStateContext();
  const [users, setAllUsers] = useState([]);
  const [tickets, setAllTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
    watch: watchPassword,
    reset: resetPassword,
  } = useForm();

  const handleUpdatePassword = async (passwordData) => {
    const { newPassword, confirmPassword } = passwordData;

    if (newPassword !== confirmPassword) {
      toast.error('Password does not match');
      return;
    }

    let sendData = {
      password: newPassword,
    };

    setError('');
    setSuccess('');
    try {
      setLoading(true);
      const response = await updateUserPassword(
        sendData,
        user?.token,
        setError
      );
      if (response && response?.status === 200) {
        setSuccess(response.data.message);
        setTimeout(() => {
          setSuccess('');
          setError('');
          resetPassword();
        }, 2000);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSuccess('');
    }
  };

  return (
    <>
      <div className="flex relative container mx-auto pt-1">
        {activeMenu ? (
          <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
            <UserSidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <UserSidebar />
          </div>
        )}
        <div
          className={
            activeMenu
              ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
              : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
          }
        >
          <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
          </div>
          <div>
            <div className="m-2 md:ml-8 p-2 md:p-1 bg-white rounded-1xl">
              <HeaderProfile title={`Settings`} />

              <div className="flex flex-col items-start justify-start">
                <div className="pb-3 mt-6 md:w-4/5 lg:w-3/4 w-full">
                  <div>
                    <div className="relative w-full">
                      <div className="flex justify-center items-center h-1 absolute w-full mb-6">
                        {loading && (
                          <div className="flex justify-center items-center h-screen">
                            <BarLoader
                              color="red"
                              loading={loading}
                              size={30}
                            />
                          </div>
                        )}

                        {success && (
                          <h5 className="text-center text-green-500 auth_error_success text-success flex justify-content-center align-items-center">
                            <FaRegCircleCheck
                              size={20}
                              style={{
                                marginTop: 2,
                              }}
                            />
                            <span className="ml-1">{success}</span>
                          </h5>
                        )}
                        {error && (
                          <h5 className="text-center text-red-400 auth_error_success text-danger flex justify-content-center align-items-center">
                            <GoAlert
                              size={20}
                              color="red"
                              style={{
                                marginTop: 1,
                              }}
                            />
                            <span className="ml-1">{error}</span>
                          </h5>
                        )}
                      </div>
                    </div>

                    <form
                      onSubmit={handleSubmitPassword(handleUpdatePassword)}
                      className="pt-8"
                    >
                      <div className="mb-4">
                        <div className="w-full inline-flex bg-stone-100">
                          <div className="w-1/12 flex justify-center items-center border-r bg-stone-200 border-r-stone-300">
                            <RiLockPasswordLine size={25} />
                          </div>

                          <input
                            id="newPassword"
                            type="password"
                            {...registerPassword('newPassword', {
                              required: 'New password is required',
                            })}
                            className="block w-full p-2 text-lg rounded-sm bg-white border border-stone-200  outline-none"
                            placeholder="New password"
                          />
                        </div>

                        {errorsPassword.newPassword && (
                          <p className="text-red-500 text-xs mt-1">
                            {errorsPassword.newPassword.message}
                          </p>
                        )}
                      </div>

                      <div className="mb-4">
                        <div className="w-full inline-flex bg-stone-100">
                          <div className="w-1/12 flex justify-center items-center border-r bg-stone-200 border-r-stone-300">
                            <RiLockPasswordLine size={25} />
                          </div>

                          <input
                            id="confirmPassword"
                            type="password"
                            {...registerPassword('confirmPassword', {
                              required: 'Confirm new password is required',
                            })}
                            className="block w-full p-2 text-lg rounded-sm bg-white border border-stone-200  outline-none"
                            placeholder="Confirm new password"
                          />
                        </div>

                        {errorsPassword.confirmPassword && (
                          <p className="text-red-500 text-xs mt-1">
                            {errorsPassword.confirmPassword.message}
                          </p>
                        )}
                      </div>

                      <div className="pb-2 pt-2">
                        <button
                          className={`bg-customRed hover:bg-red-500 text-white font-bold py-3 px-4 rounded flex justify-center items-center w-full ${
                            loading && 'ActionButton'
                          }`}
                        >
                          Create new password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
