import { useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/nav/HeaderProfile'
import { useStateContext } from '../../../components/contexts/ContextProvider'
import UserSidebar from '../../../components/sidebar/UserSidebar'
import { FaUsers } from 'react-icons/fa6'
import { useEffect, useState } from 'react'
import BarLoader from 'react-spinners/BarLoader'
import LocalSearch from '../../../components/forms/LocalSearch'
import { FaRegEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { MdOutlineQuickreply } from 'react-icons/md'
import { getTicketsUsersManagement } from '../../../components/functions/ticket'
import moment from 'moment'
import Pagination from '../../../components/pagination/pagination'

const AdminTickets = () => {
  const user = useSelector((state) => state.user)
  const { activeMenu } = useStateContext()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage] = useState(20)

  const statusOptions = [
    { name: 'All', value: '' },
    { name: 'Solved', value: 'solved' },
    { name: 'Pending', value: 'pending' },
    { name: 'Waiting', value: 'waiting' },
  ]

  const ticketsList = async () => {
    try {
      setLoading(true)
      const res = await getTicketsUsersManagement(user?.token, setError)
      if (res.status === 200) {
        setData(res?.data?.tickets)
      }
      setLoading(false)
    } catch (error) {}
  }

  useEffect(() => {
    ticketsList()
  }, [])

  const handleStatusFilterChange = (e) => {
    setSelectedStatus(e.target.value)
    setCurrentPage(1)
  }

  const filteredData = data.filter((dta) => {
    return (
      (selectedStatus === '' || dta.status === selectedStatus) &&
      (dta.subject.toLowerCase().includes(keyword.toLowerCase()) ||
        dta.ticketNumber.toLowerCase().includes(keyword.toLowerCase()))
    )
  })

  const searched = (keyword) => (d) =>
    d.subject.toLowerCase().includes(keyword) ||
    d.ticketNumber.toLowerCase().includes(keyword)


  const handleRemove = async (title, id) => {
    if (window.confirm('Are you sure you want to delete?')) {
    }
  }

  function getLastRepliedRole(messages) {
    if (!messages || messages.length === 0) {
      return 'No replies'
    }
    const lastMessage = messages[messages.length - 1]
    switch (lastMessage.user.role) {
      case 'member':
        return 'User'
      case 'moderator':
        return 'Moderator'
      case 'admin':
        return 'Admin'
      default:
        return 'Unknown Role'
    }
  }

  function truncateSubject(subject, wordLimit) {
    const words = subject?.split(' ')
    if (words?.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'
    }
    return subject
  }

  //Get current data
  const indexOfLastPost = currentPage * dataPerPage
  const indexOfFirstPost = indexOfLastPost - dataPerPage
  const currentData = filteredData.slice(indexOfFirstPost, indexOfLastPost)
  const howManyPages = Math.ceil(filteredData.length / dataPerPage)

  return (
    <>
      <div className="flex relative container mx-auto pt-1">
        {activeMenu ? (
          <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
            <UserSidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <UserSidebar />
          </div>
        )}
        <div
          className={
            activeMenu
              ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
              : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
          }
        >
          <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
          </div>
          <div>
            <div className="m-2 md:ml-8 p-2 md:p-1 bg-white rounded-1xl">
              <HeaderProfile title={`Support ticket`} />

              {loading && (
                <div className="flex justify-center items-center">
                  <BarLoader color="#fe0002" loading={loading} size={20} />
                </div>
              )}
              {!loading && (
                <div className="overflow-x-auto">
                  {data && data.length === 0 && (
                    <div className="flex justify-center items-center">
                      <p className="text-slate-900">No data found</p>
                    </div>
                  )}
                  {data && data.length > 0 && (
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      <div className="max-w-2xl flex-shrink-0">
                        <LocalSearch
                          keyword={keyword}
                          setKeyword={setKeyword}
                        />
                      </div>

                      <div
                        className="relative border rounded py-2 flex-shrink-0 flex justify-center items-center mb-4 md:mb-0 "
                        style={{ top: -4 }}
                      >
                        <label className="pl-4">Status:</label>
                        <div className="relative">
                          <select
                            value={selectedStatus}
                            onChange={handleStatusFilterChange}
                            className="appearance-none ml-2 focus:outline-none pl-2 pr-8 rounded"
                          >
                            {statusOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M5.516 7.548c0.436-0.446 1.043-0.481 1.576 0L10 10.504l2.908-2.956c0.533-0.481 1.141-0.446 1.576 0 0.436 0.445 0.408 1.197 0 1.615l-3.42 3.493c-0.268 0.272-0.701 0.467-1.063 0.467s-0.795-0.195-1.064-0.467l-3.42-3.493c-0.408-0.418-0.436-1.17 0-1.615z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="space-y-4">
                    {currentData?.filter(searched(keyword)).map((dt) => (
                      <div
                        key={dt?._id}
                        className="bg-white p-4 border border-gray-200 rounded-lg"
                      >
                        <div className="flex flex-wrap -mx-1">
                          {/* First and Second Row */}
                          <div className="w-full md:w-3/4 px-1 py-1 break-words">
                            <div className="flex flex-wrap">
                              <div className="w-full md:w-1/3 px-1 py-1">
                                <strong>Subject:</strong>{' '}
                                {truncateSubject(dt?.subject, 7)}
                              </div>
                              <div className="w-full md:w-1/3 px-1 py-1">
                                <strong>Ticket:</strong> #{dt?.ticketNumber}
                              </div>
                              <div className="w-full md:w-1/3 px-1 py-1 capitalize">
                                <strong>Status:</strong>
                                <span
                                  className={` px-3 text-white ml-1 rounded-lg flex-row justify-center items-center ${
                                    dt?.status === 'pending'
                                      ? 'bg-red-500'
                                      : dt?.status === 'waiting'
                                      ? 'bg-orange-500'
                                      : dt?.status === 'solved'
                                      ? 'bg-gray-500'
                                      : 'bg-blue-500'
                                  }`}
                                >
                                  {dt?.status}
                                </span>
                              </div>

                              <div className="w-full md:w-1/3 px-1 py-1">
                                <strong>Created:</strong>{' '}
                                {moment(dt?.createdAt).fromNow()}
                              </div>
                              <div className="w-full md:w-1/3 px-1 py-1">
                                <strong>Last activity:</strong>{' '}
                                {moment(dt?.updatedAt).fromNow()}
                              </div>
                              <div className="w-full md:w-1/3 px-1 py-1">
                                <strong>Last replied by:</strong>{' '}
                                {getLastRepliedRole(dt?.messages)}
                              </div>
                            </div>
                          </div>

                          <div className="w-full md:w-1/4 px-1 py-1 flex justify-end items-center space-x-2">
                            <Link
                              to={`/admin/tickets/request/${dt?._id}`}
                              className="text-green-500"
                            >
                              <MdOutlineQuickreply size="24" />
                            </Link>
                            {/* <button
                              onClick={() => handleRemove(dt?.subject, dt?._id)}
                              className="text-red-500"
                            >
                              <MdDelete size="24" />
                            </button> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {!loading && currentData && currentData?.length > 0 && (
                    <div className="py-20">
                      <Pagination
                        pages={howManyPages}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminTickets
