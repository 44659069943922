import React, { useState } from 'react'
import { LiaMapMarkedAltSolid } from 'react-icons/lia'
import { MdEmail } from 'react-icons/md'
import { PiPhone } from 'react-icons/pi'
import { useForm } from 'react-hook-form'

import BarLoader from 'react-spinners/BarLoader'
import { CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { sendEmailContact } from '../../components/functions/user'
import ReCAPTCHA from 'react-google-recaptcha'
import './style.css'

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [successData, setSuccessData] = useState('')
  const [errorData, setErrorData] = useState('')
  const [loadingData, setLoadingData] = useState(false)

  const [recaptchaValue, setRecaptchaValue] = useState(null)

  const onChangeRecaptcha = (value) => {
    setRecaptchaValue(value)
  }

  const onSubmit = async (data) => {
    if (!recaptchaValue) {
      setErrorData('Please verify that you are a human!')
      return
    }

    const newdata = {
      name: data.name,
      email: data.email,
      subject: data.subject,
      message: data.message,
    }
    setErrorData('')
    setSuccessData('')

    try {
      setLoadingData(true)
      const res = await sendEmailContact(newdata, setErrorData)

      if (res && res.status === 200) {
        setSuccessData('Email sent successfully')
        setTimeout(() => {
          setSuccessData('')
          setErrorData('')
          reset()
        }, 2000)
      }
      setLoadingData(false)
    } catch (error) {
      setLoadingData(false)
    }
  }
  return (
    <div className="w-full py-52 px-4 bg-white" id="contact">
      <div className="max-w-[1240px] mx-auto mb-10">
        <div className="text-center">
          <h3 className="text-3xl text-black">Contact Us</h3>
          <p className="my-5 text-md text-center text-black">
            We're here to help and answer any question you might have. We look
            forward to hearing from you.
          </p>
        </div>
      </div>

      <div className="w-full my-7">
        <form
          className="max-w-[1240px] mx-auto p-6 bg-white rounded-lg shadow-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          {loadingData && (
            <div className="flex justify-center items-center">
              <BarLoader color="#fe0002" loading={loadingData} size={20} />
            </div>
          )}

          {successData && (
            <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
              <CheckCircleFilled
                style={{
                  fontSize: '22px',
                  color: '#50C878',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />
              {successData}
            </h5>
          )}

          {errorData && (
            <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
              <ExclamationCircleOutlined
                style={{
                  fontSize: '20px',
                  color: '#FAA0A0',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />{' '}
              {errorData}
            </h5>
          )}
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2 text-gray-800">
              Name:
            </label>
            <input
              type="text"
              id="name"
              {...register('name', { required: 'Name is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            />
            {errors.name && (
              <span className="text-red-500">{errors.name.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2 text-gray-800">
              Email:
            </label>
            <input
              type="email"
              id="email"
              {...register('email', { required: 'Email is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            />
            {errors.email && (
              <span className="text-red-500">{errors.email.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="subject" className="block mb-2 text-gray-800">
              Subject:
            </label>
            <input
              type="text"
              id="subject"
              {...register('subject', { required: 'Subject is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            />
            {errors.subject && (
              <span className="text-red-500">{errors.subject.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block mb-2 text-gray-800">
              Message:
            </label>
            <textarea
              id="message"
              {...register('message', { required: 'Message is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            ></textarea>
            {errors.message && (
              <span className="text-red-500">{errors.message.message}</span>
            )}
          </div>

          <div className="flex justify-center items-center my-4">
            <div className="recaptcha-container">
              <ReCAPTCHA
                // sitekey="6LeTzUApAAAAAD4dZz8HO6PtzLQmqBAelM9L01Zm"
                sitekey="6LelzUApAAAAAJwH7rY9FnVLvHYKVcr7T_6PU3Ao"
                onChange={onChangeRecaptcha}
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={loadingData}
            className={`bg-customRed mt-2 hover:bg-red-500 text-white font-bold py-2 px-4 rounded flex justify-center items-center w-full ${
              loadingData && 'ActionButton'
            }`}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contact
